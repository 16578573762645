import { SEO } from '@oneofnone/react'
import { Section } from 'maker-ui'

export default function HomePage() {
  return (
    <Section className="container" css={{ paddingBottom: 100 }}>
      <SEO admin />
      <div className="absolute cover flex align-center justify-center">
        <h2 className="font-oblique caps">GM!</h2>
      </div>
    </Section>
  )
}
